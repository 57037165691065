import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import instance from "../api/api_instance";
import toastr from "toastr";
import CookieConsent from "react-cookie-consent";

export default function Footer() {
  const [footer, setFooter] = useState(null);
  const [offices, setOffices] = useState(null);
  const [settings, setSettings] = useState(null);
  const [formData, setFormData] = useState({
    company_name: "",
    name: "",
    email: "",
    mobile_no: "",
    country: "",
    enquiry_type: "",
  });

  const getFooter = async () => {
    try {
      const res = await instance.post("/getFooter");
      setFooter(res.data.footer);
      setOffices(res.data.Offices);
      console.log("footer", res.data.footer);
    } catch (error) {
      toastr.error(error.message);
    }
  };

  const getSettingData = async () => {
    try {
      const res = await instance.get("/getAllsettings");
      setSettings(res.data.data);
      console.log("setting data", res.data.data);
    } catch (error) {
      toastr.error(error.message);
    }
  };

  const socialMediaKeys = {
    social_link_x: "X",
    social_link_youtube: "YouTube",
    social_link_facebook: "Facebook",
    social_link_linkedin: "LinkedIn",
    whatsapp: "Whatsapp",
    instagram: "instagram",
    tiktok: "tiktok",
  };

  const updateFavicon = (faviconUrl) => {
    const faviconLink = document.getElementById("favicon-link");
    if (faviconLink) {
      faviconLink.href = faviconUrl;
    }
  };

  useEffect(() => {
    getFooter();
    getSettingData();
  }, []);

  useEffect(() => {
    if (settings) {
      const faviconUrl =
        settings.find((item) => item.key === "favicon")?.value ||
        "default-favicon.png";
      updateFavicon(faviconUrl);

      const logoUrl = settings.find((item) => item.key === "logo")?.value || "";
      localStorage.setItem("mainLogo", logoUrl);
    }
  }, [settings]);

  if (!footer || !settings) {
    return <p></p>;
  }

  // Extract and map data based on keys
  const companyLinks =
    footer.find((item) => item.key === "company")?.value || [];
  const quickLinks =
    footer.find((item) => item.key === "quick_links")?.value || [];
  const supportLinks =
    footer.find((item) => item.key === "support")?.value || [];
  const footerTextAdmin =
    settings.find((item) => item.key === "footer_text_site")?.value ||
    "Copyright © 2024 Elevate Auditing";
  const socialLinks = settings.filter((item) =>
    Object.keys(socialMediaKeys).includes(item.key)
  );

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData({
      ...formData,
      [name]: value,
    });
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    try {
      const res = await instance.post(`/contact-us`, formData);
      toastr.success(res.data.message);
      setFormData({
        company_name: "",
        name: "",
        email: "",
        mobile_no: "",
        country: "",
        enquiry_type: "",
      });
    } catch (error) {
      console.error(error.message);
      toastr.error(error.message);
    }
  };

  return (
    <>
      <div className="xfpage page basicpage">
        <div className="xf-content-height">
          <div className="aem-Grid aem-Grid--12 aem-Grid--default--12 ">
            <div className="freeflowhtml aem-GridColumn aem-GridColumn--default--12">
              <div className="article-details-wraper subscribe-wraper">
                <Link
                  to="#"
                  className="subscribe bg-green"
                  id="subscribe-insights"
                  data-bs-toggle="modal"
                  data-bs-target="#resume-form-popup"
                >
                  Enquire Now
                </Link>
              </div>
            </div>
          </div>
        </div>
      </div>
      {/* <!-- Footer Start --> */}
      <div className="footer parbase aem-GridColumn aem-GridColumn--default--12">
        <footer
          style={
            {
              // backgroundImage: `linear-gradient(to top, #44377da3, #44377d9c), url(${homeData?.testimonial?.section_2[0]?.image ?? 'default-image.jpg'})`,
              //backgroundImage: `linear-gradient(to top, #18107b00, #ffffffbd), url(/asstes/img/footer-new.jpg)`,
              //backgroundRepeat: 'no-repeat',
              //backgroundPosition: 'bottom',
              //backgroundSize: 'cover'
            }
          }
        >
          <article>
            <div className="container pt50 pb50 onmobilecenter">
              <div className="row">
                <div
                  className="col-12 col-md-12 col-lg-4 col-sm-12 col-xs-12"
                  data-aos="fade-right"
                  data-aos-easing="ease"
                  data-aos-duration="2000"
                >
                  <a
                    aria-current="page"
                    class="ftr-head mt-sm-20"
                    id="footer-logo"
                    href="/"
                  >
                    <img
                      src="https://elevatebpm.com/admin/assets/media/img/logo.png"
                      alt="Logo"
                    />
                  </a>
                  <h3 className="ftr-head mt-sm-20">Connect with us</h3>
                  <p className="mt-2 mb-3" style={{ fontSize: "small" }}>
                    We are available across the globe to help <br /> reach out
                    to all potential entrepreneurs <br />
                    and existing business owners with our auditing, accounting,
                    and IT services.
                  </p>
                  <ul
                    className="list-inline footer-txt"
                    id="footer-social-icons"
                  >
                    {socialLinks.map((link) => (
                      <li key={link.key}>
                        <Link
                          title={socialMediaKeys[link.key]}
                          target="_blank"
                          to={link.value}
                        >
                          <img
                            src={`../asstes/img/${socialMediaKeys[
                              link.key
                            ].toLowerCase()}-icon.png`}
                            width="16"
                            height="16"
                            alt={socialMediaKeys[link.key]}
                          />
                        </Link>
                      </li>
                    ))}
                  </ul>
                </div>
                {/* <div
                  className="col-md-3 col-sm-3 col-xs-12"
                  data-aos="fade-left"
                  data-aos-easing="ease"
                  data-aos-duration="2000"
                >
                  <h3 className="ftr-head mt-xs-20 mt-0">Company</h3>
                  <ul className="list-unstyled footer-txt">
                    {companyLinks.map((item) => (
                      <li key={item.id}>
                        <Link to={`/${item.slug}`}>
                          <i class="fa fa-angle-right"></i> {item.name}
                        </Link>
                      </li>
                    ))}
                  </ul>
                </div>
                <div
                  className="col-md-3 col-sm-3 col-xs-12"
                  data-aos="fade-left"
                  data-aos-easing="ease"
                  data-aos-duration="2000"
                >
                  <h3 className="ftr-head mt-xs-20 mt-0">Quick Links</h3>
                  <ul className="list-unstyled footer-txt">
                    {quickLinks.map((item) => (
                      <li key={item.id}>
                        <Link to={`/${item.slug}`}>
                          <i class="fa fa-angle-right"></i> {item.title}
                        </Link>
                      </li>
                    ))}
                  </ul>
                </div>
                <div
                  className="col-md-2 col-sm-3 col-xs-12"
                  data-aos="fade-right"
                  data-aos-easing="ease"
                  data-aos-duration="2000"
                >
                  <h3 className="ftr-head mt-xs-20 mt-0">Support</h3>
                  <ul className="list-unstyled footer-txt">
                    {supportLinks.map((item) => (
                      <li key={item.id}>
                        <Link to={`/${item.slug}`}>
                          <i class="fa fa-angle-right"></i> {item.name}
                        </Link>
                      </li>
                    ))}
                  </ul>
                </div> */}
                <div
                  className="col-12 col-md-12 col-lg-8 col-sm-12 col-xs-12"
                  data-aos="fade-left"
                  data-aos-easing="ease"
                  data-aos-duration="2000"
                >
                  <div className="footer-contact-details">
                    {offices?.map((item, index) => (
                      <div
                        className="contact-details"
                        style={{ marginLeft: "40px" }}
                      >
                        <h3 className="ftr-head mt-xs-20 mt-0">
                          {item.company_name}
                        </h3>
                        <h4>{item.trade_id}</h4>
                        <div className="textwidget-p">
                          <p style={{ color: "#000" }}>{item.address}</p>
                          <p>
                            <span style={{ color: "#000" }}>
                              <b>Tel No :</b>{" "}
                              <a style={{ color: "#000" }} href="tel:044258131">
                                {item.phone}
                              </a>
                            </span>
                          </p>
                        </div>
                      </div>
                    ))}
                  </div>
                </div>
              </div>
            </div>
            <div
              className="container-fluid ptb15"
              style={{ backgroundColor: "#ffff" }}
            >
              <div className="container">
                {/* <div className="row">
                                    <div className="col-md-12 col-sm-7 col-xs-12 onmobilecenter">
                                        <p className="mb0" style={{ textAlign: "center", fontSize: "small", color: "black" }}>
                                            {footerTextAdmin}
                                        </p>
                                    </div>
                                </div> */}
                <div className="row">
                  <div className="col-md-12 col-sm-12 col-xs-12 text-center">
                    <p className="footer-text-line mb0">{footerTextAdmin}</p>
                  </div>
                  {/* <div className="col-md-4 col-sm-5 col-xs-12">
                                        <div className="pull-right pull-left-xs mt-xs-20">
                                        <div className="country">
                                            <div className="select-country"><a href="javascript:void(0);">Select Country <span className="down-arrow"></span> </a></div>
                                            <div className="option-country">
                                            <ul className="list-unstyled country-align">
                                                
                                                <li><a href="#">Australia</a></li>
                                                
                                                <li><a href="#">Brazil</a></li>
                                                
                                                <li><a href="#">Canada</a></li>
                                                
                                                <li><a href="#">China</a></li>
                                                
                                                <li><a href="#">Costa Rica</a></li>
                                                
                                                <li><a href="#">Czech Republic</a></li>
                                                
                                            </ul>
                                            </div>
                                        </div>
                                        </div>
                                    </div> */}
                </div>
              </div>
            </div>
          </article>
        </footer>
        <a className="scroll-up">
          <img
            src="../asstes/img/background-image-arrow_up.png"
            alt="Arrow up"
            className="img-responsive"
          />
        </a>
      </div>
      {/* <!-- Footer End --> */}

      {/* <!-- START Bootstrap-Cookie-Alert --> */}
      <CookieConsent
        location="bottom"
        enableDeclineButton="Decline"
        buttonText="Accept"
        style={{ background: "#2B373B" }}
        buttonStyle={{ color: "#4e503b", fontSize: "13px" }}
        expires={150}
      >
        This site uses cookies to provide you with a more responsive and
        personalized service. By using this site you agree to our use of
        cookies. <br />
        Please read our cookie notice for more information on the cookies we use
        and how to delete or block them.
      </CookieConsent>

      {/* <!-- ENDS Bootstrap-Cookie-Alert --> */}

      {/* <!-- Contact us Popup start --> */}
      <div
        className="modal fade"
        id="resume-form-popup"
        tabindex="-1"
        aria-labelledby="exampleModalLabel"
        aria-hidden="true"
      >
        <div className="modal-dialog modal-dialog-centered">
          <div className="modal-content">
            <div className="modal-header">
              <h5 className="modal-title">Get in Touch</h5>
              <button
                type="button"
                className="close"
                data-bs-dismiss="modal"
                aria-label="Close"
              >
                <span aria-hidden="true">×</span>
              </button>
            </div>
            <form onSubmit={handleSubmit}>
              <div className="modal-body">
                <p>
                  Share your details and our team member will reach out to you
                  to answer all your queries
                </p>
                <hr style={{ color: "#1a0d54" }} />

                <div className="form-group row">
                  <label className="col-sm-4 col-form-label">
                    Company
                    <img
                      src="asstes/img/required.png"
                      style={{ position: "absolute" }}
                      alt="required"
                    />
                  </label>
                  <div className="col-sm-8">
                    <input
                      type="text"
                      className="form-control"
                      id="company_name"
                      name="company_name"
                      value={formData.company_name}
                      onChange={handleChange}
                      required
                    />
                  </div>
                </div>

                <div className="form-group row">
                  <label className="col-sm-4 col-form-label">
                    Full name
                    <img
                      src="asstes/img/required.png"
                      style={{ position: "absolute" }}
                      alt="required"
                    />
                  </label>
                  <div className="col-sm-8">
                    <input
                      type="text"
                      className="form-control"
                      id="name"
                      name="name"
                      value={formData.name}
                      onChange={handleChange}
                      required
                    />
                  </div>
                </div>

                <div className="form-group row">
                  <label className="col-sm-4 col-form-label">
                    Email
                    <img
                      src="asstes/img/required.png"
                      style={{ position: "absolute" }}
                      alt="required"
                    />
                  </label>
                  <div className="col-sm-8">
                    <input
                      type="email"
                      className="form-control"
                      id="email"
                      name="email"
                      value={formData.email}
                      onChange={handleChange}
                      required
                    />
                  </div>
                </div>

                <div className="form-group row">
                  <label className="col-sm-4 col-form-label">
                    Mobile
                    <img
                      src="asstes/img/required.png"
                      style={{ position: "absolute" }}
                      alt="required"
                    />
                  </label>
                  <div className="col-sm-8">
                    <input
                      type="number"
                      className="form-control"
                      id="mobile_no"
                      name="mobile_no"
                      value={formData.mobile_no}
                      onChange={handleChange}
                      required
                    />
                  </div>
                </div>

                <div className="form-group row">
                  <label className="col-sm-4 col-form-label">Country</label>
                  <div className="col-sm-8">
                    <input
                      type="text"
                      className="form-control"
                      id="country"
                      name="country"
                      value={formData.country}
                      onChange={handleChange}
                      required
                    />
                  </div>
                </div>

                <div className="form-group row">
                  <label className="col-sm-4 col-form-label">
                    Enquiry Type
                    <img
                      src="asstes/img/required.png"
                      style={{ position: "absolute" }}
                      alt="required"
                    />
                  </label>
                  <div className="col-sm-8">
                    <select
                      className="form-select"
                      id="enquiry_type"
                      name="enquiry_type"
                      value={formData.enquiry_type}
                      onChange={handleChange}
                      required
                    >
                      <option selected value="select option">
                        Select Option
                      </option>
                      <option value="AUDITING">AUDITING</option>
                      <option value="ACCOUNTING & VAT">ACCOUNTING & VAT</option>
                      <option value="VAT">VAT</option>
                      <option value="CORPORATE TAX SERVICES">
                        CORPORATE TAX SERVICES
                      </option>
                      <option value="AML & COMPLIANCE">AML & COMPLIANCE</option>
                      <option value="DIGITAL MARKETING">
                        DIGITAL MARKETING
                      </option>
                      <option value="CORPORATE SERVICES">
                        CORPORATE SERVICES
                      </option>
                      <option value="ICV CERTIFICATION SERVICE">
                        ICV CERTIFICATION SERVICE
                      </option>
                      <option value="LIQUIDATION">LIQUIDATION</option>
                      <option value="ESR & PRO SERVICES">
                        ESR & PRO SERVICES
                      </option>
                      <option value="BUSINESS COLLABORATION">
                        BUSINESS COLLABORATION
                      </option>
                    </select>
                  </div>
                </div>
              </div>

              <div className="modal-footer">
                <button type="submit" className="btn btn-primary">
                  Contact Us
                </button>
              </div>
            </form>
          </div>
        </div>
      </div>
      {/* <!-- Contact us Popup ends --> */}
    </>
  );
}
