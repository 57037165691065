import { BrowserRouter, Routes, Route } from "react-router-dom";
import Header from "./Components/Header";
import Footer from "./Components/Footer";
import { lazy, Suspense } from "react";
import { useLoading } from "./context/LoadingContext";
import ScrollToTop from "./Components/ScrollToTop";

const Home = lazy(() => import("./Components/Home"));
const About = lazy(() => import("./Components/About"));
const Blog = lazy(() => import("./Components/Blog"));
const Careers = lazy(() => import("./Components/Careers"));
const Blog_details = lazy(() => import("./Components/BlogDetails"));
const Contact = lazy(() => import("./Components/Contact"));
const Loder = lazy(() => import("./Components/common/Loader"));
const PageSectionBySlug = lazy(() =>
  import("./Components/common/PageSectionBySlug")
);

function App() {
  const { loading, setLoading } = useLoading();
  return (
    <>
      {/* {loading && <Loder />} */}
      <BrowserRouter>
        <ScrollToTop />
        <Suspense fallback={<Loder />}>
          <Routes>
            <Route exact path="/">
              <Route index element={<Home />} />
              <Route path="home" element={<Home />} />
              <Route path="/about-us" element={<About />} />
              <Route path="blogs" element={<Blog />} />
              <Route path="blog/details/:id" element={<Blog_details />} />
              <Route path="career" element={<Careers />} />
              <Route path="contact" element={<Contact />} />
              <Route path=":slug" element={<PageSectionBySlug />} />
            </Route>
          </Routes>
        </Suspense>
      </BrowserRouter>
    </>
  );
}

// function wait(time) {
//     return new Promise(resolve => {
//         setTimeout(resolve, time)
//     })
// }

export default App;
